const pantalla = window.location.pathname;
document.addEventListener("DOMContentLoaded", function () {
  // Elementos que se cargan siempre

  window.scrollTo(0, 0);
  ajax();
  dataTables();
  selecttwo();

  // carga condicional de scripts
  if (rutas(["/lista-rutina"])) {
    $(".modal_rutina").on("click", (e) => modal_rutina(e, true));
  }
  if (rutas(["/crear-entrenamiento", "/editar-entrenamiento"])) {
    entrenamiento();
  }
});

const rutas = (rutas) => rutas.includes(pantalla);

// Funciones globales

function ajax() {
  $("#guardar-registro").on("submit", function (e) {
    e.preventDefault();
    let datos = $(this).serializeArray();
    $.ajax({
      type: $(this).attr("method"),
      data: datos,
      url: window.location.pathname,
      dataType: "json",
      success: function (data) {
        responseHandler(data);
      },
    });
  });
  $("#guardar-registro-archivo").on("submit", function (e) {
    e.preventDefault();
    let datos = new FormData(this);
    $.ajax({
      type: $(this).attr("method"),
      data: datos,
      url: window.location.pathname,
      dataType: "json",
      contentType: false,
      processData: false,
      async: true,
      cache: false,
      success: function (data) {
        responseHandler(data);
      },
    });
  });
  $("#login-adminn").on("submit", function (e) {
    e.preventDefault();
    let datos = $(this).serializeArray();
    $.ajax({
      type: "POST",
      data: datos,
      url: window.location.pathname,
      dataType: "json",
      success: function (data) {
        let {resultado} = data;
        if (resultado == "error") {
          Swal.fire({
            icon: "error",
            title: "Credenciales incorrectas",
            text: "Revisa tus credenciales",
            footer: "",
          });
        } 
      },
    });
  });

  $(".borrar").on("click", function (e) {
    e.preventDefault();
    const id = $(this)[0].dataset.id;
    const tipo = $(this)[0].dataset.tipo;

    Swal.fire({
      title: "¿Estas seguro?",
      text: "Esta accion no se puede revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Borrar",
    }).then((result) => {
      const body = new FormData();
      body.append("id", id);

      fetch(`/${tipo}/eliminar`, {
        method: "post",
        body,
      })
        .then((res) => res.json())
        .then((respuesta) => {
          if (respuesta.respuesta === "exito") {
            jQuery('[data-id="' + id + '"]')
              .parents("tr")
              .remove();
            Swal.fire("Correcto", "Se elimino correctamente", "success");
          }
        });
    });
  });

  function responseHandler(
    data,
    correct_title = "Correcto",
    correct_text = "Se guardaron los cambios",
    error_title = "Error",
    error_text = "Algo salió mal, intenta más tarde"
  ) {
    if (data.respuesta == "exito") {
      Swal.fire(correct_title, correct_text, "success");
      setTimeout(() => {
        window.location.replace(data.regreso ?? "/");
      }, 3000);
    } else {
      Swal.fire({
        icon: "error",
        title: error_title,
        text: error_text,
        footer: "",
      });
    }
  }
}

function dataTables() {
  if ($.fn.dataTable.isDataTable("#tabla")) {
    table = $("#tabla").DataTable();
  } else {
    table = $("#tabla").DataTable({
      paging: true,
      lengthChange: false,
      searching: true,
      ordering: true,
      info: true,
      autoWidth: false,
      responsive: true,
      language: {
        paginate: {
          next: "Siguiente",
          previous: "Anterior",
          last: "Ultimo",
          first: "Primero",
        },
        info: "Mostrando _START_ a _END_ de _TOTAL_ resultados",
        emptyTable: "No hay registros",
        infoEmpty: "0 Registros",
        search: "Buscar: ",
      },
    });
  }
}
function selecttwo() {
  bsCustomFileInput.init();
  $(".select2").select2();
}

// ========= Funciones de CRUD ejercicios,rutinas,entrenamientos,admins
// Modal de rutina

function modal_rutina(e) {
  // === Iremos construyendo la UI por medio de DOM scripting y innerHTML

  // Datos Iniciales
  const id = e.currentTarget.dataset.id;
  const padre = $("body");

  const modal = document.createElement("div");
  modal.classList.add("modal");
  modal.innerHTML = `
  <div class="cuerpo">
      <div class="row">
          <button class="col-6 btn btn-danger cerrar">CERRAR</button>
      </div>
      <h3> Ejercicios de la Rutina </h3>
      <div class="row centrar">

      </div>
  </div>
  `;
  padre.prepend(modal);

  // Hacemos la consulta asincrona para que mientras se haga lo demas
  const body = new FormData();
  body.append("id", id);
  spinner(modal);
  fetch("/rutina", {
    method: "POST",
    body,
  })
    .then((res) => res.json())
    .then((data) => tarjeta_builder(data));

  const tarjetas = $(modal).find(".centrar")[0]; //Seleccinamos el row donde van los ejercicios
  const cerrar = $(modal).find(".cerrar"); //Seleccionamos el boton de cerrar
  cerrar.on("click", (e) => $(e.target).parent().parent().parent().remove());

  function tarjeta_builder(data) {
    let { ejercicios } = data;
    console.log(ejercicios)

    ejercicios.forEach((ejercicio, i) => {
      const tarjeta = document.createElement("div");
      tarjeta.classList.add("col-md-3");
      tarjeta.classList.add("m-3");
      tarjeta.classList.add("tarjeta");
      //Creamos una tarjeta de prueba
      tarjeta.innerHTML = `
          
              <div class="row">
                  <div class="form-group col-md-12">
                      <label for="ejercicio">Ejercicio</label>
                      <input type="text" class="form-control" id="ejercicio" value="${ejercicio.nombre}" disabled>
                  </div>
              </div>
              <div class="row">
                  <div class="form-group col-md-6">
                      <label for="serie">Series</label>
                      <input type="text" class="form-control" id="serie" value="${ejercicio.serie}" disabled>
                  </div>

                  <div class="form-group col-md-6">
                      <label for="repeticion">Repeticiones</label>
                      <input type="text" class="form-control" id="repeticion" value="${ejercicio.repeticion}" disabled>
                  </div>

              </div>

              <div class="row">
                  <div class="form-group col-md-12">
                      <label for="repeticion">Descanso</label>
                      <input type="text" class="form-control" id="descanso" value="${ejercicio.descanso}" disabled>
                  </div>
              </div>

              ${ejercicio.metodoIntensidad === "pesoInicial" ? (
                `
                <div class="row">
                        <div class="form-group col-md-12">
                            <label for="pesoInicial">Peso Inicial</label>
                            <input type="number" class="form-control" placeholder="Peso Inicial" value="${ejercicio.pesoInicial}" disabled>
                        </div>
                </div>
                
                `
              ) : (
                `
                <div class="row">
                        <div class="form-group col-md-12">
                            <label for="carga">% Trabajo</label>
                            <input type="number" class="form-control" placeholder="Porcentaje de carga" value="${ejercicio.carga}" disabled>
                        </div>
                </div>
                
                `
              )}

              `;
      //Juntamos todo
      tarjetas.appendChild(tarjeta);
    });
    spinner(modal, true);
  }
}

// Codigo de entrenamiento
function entrenamiento() {
  if (rutas(["/crear-entrenamiento"])) {
    //rutina_component();
  }

  const select = $(".select2");
  select.on("change", selectChangeEntrenamiento);

  const eliminar = $(".btn-danger");
  eliminar.click((e) => {
    e.preventDefault();
    $(e.currentTarget).parent().parent().remove();
  });

  const boton_ver = $(".ver-entrenamiento");
  boton_ver.click((e) => {
    e.preventDefault();
    const id = $(e.currentTarget).parent().parent().find(".select2").val();
    verBotonHandler(id);
  });

  // == Despues de añadir las acciones al primer render seleccionamos el boton que crea los elementos dinamicos
  const boton = document.querySelector("#agregar");
  boton.addEventListener("click", (e) => {
    e.preventDefault();
    rutina_component();
  });

  function rutina_component() {
    // === ELemento dinamico

    // === El primer elemento a redenrizar será el elemento padre
    const rutina = document.createElement("div");
    rutina.classList.add("tarjeta");
    rutina.classList.add("col-md-3");
    rutina.classList.add("m-3");

    rutina.innerHTML = `

    <div class="row">
        <div class="form-group col-12">
            <label>Rutina</label>
            <select class="select2" style="width: 100%;" name="rutina[]">
                <option disabled selected="selected" >---Seleccionar---</option>
            </select>
        </div>

    </div>

    <div class="row">
        <div class="form-group col-md-6">
            <label for="nivel">Nivel</label>
            <input type="text" id="nivel" class="form-control" readonly>
        </div>
        <div class="form-group col-md-6">
            <label for="subnivel">Subnivel</label>
            <input type="number" id="sub_nivel" class="form-control" readonly >
        </div>
    </div>
            
`;

    // === Después de haber rederizado el elemento padre ejecutaremos las funciones que inicialice cualquier librería externa, en este caso select2

    // === Igualmente seguiremos el patron de decalración y evento
    const select = $(rutina).find(".select2");
    select.on("change", selectChangeEntrenamiento);
    select.select2();

    // ==== En el momento en el que ya se renderizarón los elementos de la UI que requieren de datos de la API se hace el llamado a fetch
    fetch("/rutina")
      .then((data) => data.json())
      .then((rutinas) => {
        rutinas.forEach((rutina) => {
          const { id_rutina, nombre } = rutina;
          select.append(new Option(nombre, id_rutina, false, false));
        });
      });

    // === Despues de inicializar las librerías de terceros añadimos el elemento padre al dom
    $("#agregar").before(rutina);

    // === Ahora crearemos los elementos que tendrán interactividad y seguimos el patrón declaración-asignación

    const buttonRow = document.createElement("div");
    buttonRow.classList.add("row");
    rutina.appendChild(buttonRow);

    //Boton de eliminar
    const eliminar = document.createElement("button");
    eliminar.classList.add("btn");
    eliminar.classList.add("btn-danger");
    eliminar.classList.add("col-9");
    eliminar.textContent = "Eliminar";

    eliminar.addEventListener("click", function (e) {
      e.preventDefault();
      $(this).parent().parent().remove();
    });
    buttonRow.appendChild(eliminar);

    //Boton de ver
    const verBoton = document.createElement("button");
    verBoton.classList.add("btn");
    verBoton.classList.add("btn-primary");
    verBoton.classList.add("col-2");
    verBoton.classList.add("ver-entrenamiento");
    verBoton.innerHTML = `
    <i class="fa-solid fa-eye-low-vision"></i>
    `;

    verBoton.addEventListener("click", (e) => {
      e.preventDefault();
      const id = select.val();
      verBotonHandler(id);
    });

    buttonRow.appendChild(verBoton);
  }
  function selectChangeEntrenamiento() {
    let id = $(this).val();
    let padre = $($(this).parents()[2]);
    let nivel = padre.find("#nivel");
    let sub_nivel = padre.find("#sub_nivel");

    const body = new FormData();
    body.append("id", id);

    fetch("/rutina", {
      method: "post",
      body,
    })
      .then((res) => res.json())
      .then((data) => {
        nivel.val(data.nivel);
        sub_nivel.val(data.sub_nivel);
      });
  }
  function verBotonHandler(id) {
    if (!id) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Debes seleccionar una rutina para poder previsualizarla",
        footer: "",
      });
      return;
    }
    const entrada = {
      currentTarget: {
        dataset: {
          id,
        },
      },
    };
    modal_rutina(entrada);
  }
}
function spinner(parent, finish = false) {
  if (!finish) {
    const spinner = document.createElement("div");
    spinner.classList.add("spinner_parent");
    spinner.innerHTML = `
    <div class="sk-chase">
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
    </div>`;
    parent.appendChild(spinner);
    return;
  }
  $(parent).find(".spinner_parent").remove();
}
function preloader(dismount=false){
  if(!dismount){
    document.querySelector(".preloader").style.height = null;
    document.querySelector(".animation__wobble").style.display = null;
    document.querySelector(".collapse").classList.remove("show");
    return
  }
  document.querySelector(".preloader").style.height = "0";
  document.querySelector(".animation__wobble").style.display = "none";

}


